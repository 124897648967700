<template>
  <div :redraw="redraw" style="overflow-x: auto">
    <table class="table table-bordered">
      <thead class="thead-light">
      <tr>
        <th rowspan="3">
          {{ $t('studentAbsenceNumberOfDays.studentName') }}
        </th>
        <template v-for="quarterMonth in quarterMonths">
          <th colspan="4" v-for="(month, index) in quarterMonth.months" :key="index">
            {{ $t('studentAbsenceNumberOfDays.months.' + month) }}
          </th>
          <th colspan="4" :key="quarterMonth.quarter">
            {{ $t('studentAbsenceNumberOfDays.quarter.' + quarterMonth.quarter) }}
          </th>
        </template>
        <th colspan="4">
          {{ $t('studentAbsenceNumberOfDays.annual') }}
        </th>
      </tr>
      <tr>
        <template v-for="quarterMonth in quarterMonths">
          <template v-for="(month, index) in quarterMonth.months">
            <th colspan="2" :key="index">
              {{ $t('studentAbsenceNumberOfDays.fullDays') }}
            </th>
            <th colspan="2" :key="index">
              {{ $t('studentAbsenceNumberOfDays.lessons') }}
            </th>
          </template>
          <th colspan="2" :key="quarterMonth.quarter">
            {{ $t('studentAbsenceNumberOfDays.fullDays') }}
          </th>
          <th colspan="2" :key="quarterMonth.quarter">
            {{ $t('studentAbsenceNumberOfDays.lessons') }}
          </th>
        </template>
        <th colspan="2">
          {{ $t('studentAbsenceNumberOfDays.fullDays') }}
        </th>
        <th colspan="2">
          {{ $t('studentAbsenceNumberOfDays.lessons') }}
        </th>
      </tr>
      <tr>
        <template v-for="quarterMonth in quarterMonths">
          <template v-for="(month, index) in quarterMonth.months">
            <th :key="index">
              {{ $t('studentAbsenceNumberOfDays.all') }}
            </th>
            <th :key="index">
              {{ $t('studentAbsenceNumberOfDays.fullDaysByIllness') }}
            </th>
            <th :key="index">
              {{ $t('studentAbsenceNumberOfDays.all') }}
            </th>
            <th :key="index">
              {{ $t('studentAbsenceNumberOfDays.lessonsByIllness') }}
            </th>
          </template>
          <th :key="quarterMonth.quarter">
            {{ $t('studentAbsenceNumberOfDays.all') }}
          </th>
          <th :key="quarterMonth.quarter">
            {{ $t('studentAbsenceNumberOfDays.fullDaysByIllness') }}
          </th>
          <th :key="quarterMonth.quarter">
            {{ $t('studentAbsenceNumberOfDays.all') }}
          </th>
          <th :key="quarterMonth.quarter">
            {{ $t('studentAbsenceNumberOfDays.lessonsByIllness') }}
          </th>
        </template>
        <th>
          {{ $t('studentAbsenceNumberOfDays.all') }}
        </th>
        <th>
          {{ $t('studentAbsenceNumberOfDays.fullDaysByIllness') }}
        </th>
        <th>
          {{ $t('studentAbsenceNumberOfDays.all') }}
        </th>
        <th>
          {{ $t('studentAbsenceNumberOfDays.lessonsByIllness') }}
        </th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="student in students" :key="student.studentId">
          <td>
            {{student.studentName}}
          </td>
          <template v-for="quarterMonth in quarterMonths">
            <template v-for="(month, index) in quarterMonth.months">
              <td :key="index">
                {{ showData(student.studentId, quarterMonth.quarter, month, 'fullDays') }}
              </td>
              <td :key="index">
                {{ showData(student.studentId, quarterMonth.quarter, month, 'fullDaysByIllness') }}
              </td>
              <td :key="index">
                {{ showData(student.studentId, quarterMonth.quarter, month, 'lessons') }}
              </td>
              <td :key="index">
                {{ showData(student.studentId, quarterMonth.quarter, month, 'lessonsByIllness') }}
              </td>
            </template>
            <td :key="quarterMonth.quarter">
              {{ showQuarterData(student.studentId, quarterMonth.quarter, 'fullDays') }}
            </td>
            <td :key="quarterMonth.quarter">
              {{ showQuarterData(student.studentId, quarterMonth.quarter, 'fullDaysByIllness') }}
            </td>
            <td :key="quarterMonth.quarter">
              {{ showQuarterData(student.studentId, quarterMonth.quarter, 'lessons') }}
            </td>
            <td :key="quarterMonth.quarter">
              {{ showQuarterData(student.studentId, quarterMonth.quarter, 'lessonsByIllness') }}
            </td>
          </template>
          <td>
            {{ showAnnualData(student.studentId, 'fullDays') }}
          </td>
          <td>
            {{ showAnnualData(student.studentId, 'fullDaysByIllness') }}
          </td>
          <td>
            {{ showAnnualData(student.studentId, 'lessons') }}
          </td>
          <td>
            {{ showAnnualData(student.studentId, 'lessonsByIllness') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}

th.rotate {
  writing-mode: vertical-rl;
}

</style>
<script>
import ApiService from "@/common/api.service";

export default {
  name: "StudentAbsenceNumberOfDaysTable",
  components: {},
  data() {
    return {
      data: {},
      quarterSum: {},
      annualSum: {},
      groupId: -1,
      quarterMonths: [],
      students: [],

      redraw: 0
    };
  },
  mounted() {

  },
  methods: {
    redrawTable(groupId) {
      this.groupId = groupId;
      let $this = this;

      ApiService.querySecured("schoolReports/studentAbsenceNumberOfDaysReport", {
        params: {groupId: groupId}
      }).then(({data}) => {
        $this.quarterMonths = data.quarterMonths;
        $this.students = data.students;

        $this.data = {};

        for (let studentIndex in data.students) {
          let student = data.students[studentIndex];
          $this.data[student.studentId] = {};
          $this.quarterSum[student.studentId] = {};
          $this.annualSum[student.studentId] = {
            fullDays: 0,
            fullDaysByIllness: 0,
            lessons: 0,
            lessonsByIllness: 0,
          };
        }

        for (let dataIndex in data.data) {
          let data1 = data.data[dataIndex];

          if (typeof $this.data[data1.studentId][data1.quarter] === "undefined") {
            $this.data[data1.studentId][data1.quarter] = {};
            $this.quarterSum[data1.studentId][data1.quarter] = {
              fullDays: 0,
              fullDaysByIllness: 0,
              lessons: 0,
              lessonsByIllness: 0,
            };
          }
          
          $this.data[data1.studentId][data1.quarter][data1.month] = data1;

          $this.quarterSum[data1.studentId][data1.quarter].fullDays += data1.fullDays;
          $this.quarterSum[data1.studentId][data1.quarter].fullDaysByIllness += data1.fullDaysByIllness;
          $this.quarterSum[data1.studentId][data1.quarter].lessons += data1.lessons;
          $this.quarterSum[data1.studentId][data1.quarter].lessonsByIllness += data1.lessonsByIllness;

          $this.annualSum[data1.studentId].fullDays += data1.fullDays;
          $this.annualSum[data1.studentId].fullDaysByIllness += data1.fullDaysByIllness;
          $this.annualSum[data1.studentId].lessons += data1.lessons;
          $this.annualSum[data1.studentId].lessonsByIllness += data1.lessonsByIllness;
        }

        $this.redraw = Math.random();
      }).catch((e) => {
        alert(e);
      });
    },
    addData(title, dataItem) {
      this.data.push(
          {
            title: title,
            data: dataItem
          }
      );
    },
    showData(studentId, quarter, month, field) {
      if(typeof this.data[studentId] != "undefined"
        && typeof this.data[studentId][quarter] != "undefined"
        && typeof this.data[studentId][quarter][month] != "undefined"
      ){
        return this.data[studentId][quarter][month][field];
      }
      
      return 0;
    },
    showQuarterData(studentId, quarter, field) {
      if(typeof this.quarterSum[studentId] != "undefined"
        && typeof this.quarterSum[studentId][quarter] != "undefined"
      ){
        return this.quarterSum[studentId][quarter][field];
      }
      
      return 0;
    },
    showAnnualData(studentId, field) {
      if(typeof this.annualSum[studentId] != "undefined"
        && typeof this.annualSum[studentId] != "undefined"
      ){
        return this.annualSum[studentId][field];
      }
      
      return 0;
    }
  }
};
</script>
